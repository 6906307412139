<template>
  <b-card title="Purchase Order List ">
    
    <b-modal v-model="modalDelete" title="Delete Purchase Order">
    <!-- Alert before Delete PO-->
      <b-container>
        <b-row>
          <b-col cols="12" >
            <div class="d-flex">
              Are you sure want to delete this {{selectPO.PONumber}} ?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="confirmDelete">Confirm</b-button>
        </div>
      </template>
    </b-modal>
    <!-- End of Modal Needed on this page-->
    <b-col cols="12">
      <b-row>
        <b-col cols="4">
          <b-button
            v-if="checkPermissionAdd"
            variant="success"
            :to="{name:'order-add'}"
          >
            Add Purchase Order
          </b-button>
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            @input="(value) => doFiltering(value)"
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>

        
        <b-col style="margin: 15px 0 0 0" cols="12">
          <b-table
            class="custom-row-height"
            :busy.sync="tableLoading"
            show-empty
            :items="mappedPurchase"
            :fields="fields"
            
            responsive
          >
            <template #cell(createdAt)="row">
              {{dates(row.item.createdAt)}}
            </template>
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item style="text-align:center">
                  <b-button size="sm" @click="detailPO(row)" variant="info" class="mr-1">
                    <feather-icon icon="EyeIcon"/>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center" v-if="row.item.orderStatus === 'Draft' && permission.edit">
                  <b-button size="sm" @click="editDraft(row)" variant="outline-primary" class="mr-1">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center" v-if="row.item.orderStatus === 'Draft' && permission.delete"> 
                  <b-button size="sm" variant="danger"  @click="deleteDraft(row.item)" class="mr-1">
                    <feather-icon icon="Trash2Icon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <!--<step-progress :length="3" :finished="3" :returned="true"></step-progress>-->
        </b-col>
        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
      
    </b-col>
    
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {userAccess,dateFormat} from '@/utils/utils'
import lodash from 'lodash';
import axios from '@/axios';
import stepProgress from '@/components/StepProgress.vue'

export default {
  components: {
    stepProgress
  },
  data() {
    return {
      fields: [
        { key: 'rspOrderNumber', label: 'Order Number', filterable: true, sortable: true },
        { key: 'customerPo', label: "Customer PO", filterable: true, sortable: true},
        // { key: 'fullfimentType', label: 'Fullfillment Type', filterable: true, sortable: true },
        { key: 'dealer.dealerCode', label: 'Dealer Code', filterable: true, sortable: true },
        { key: 'sapSo', label: 'Sap So', filterable: true, sortable: true },
        { key: 'orderStatus', label: 'Status', filterable: true, sortable: true, },
        { key: 'deliveryType', label: 'Delivery Type', filterable: true, sortable: true },
        { key: 'createdAt', label:'PO Created Time', filterable: true, sortable: true},
        { key: 'actions', label: 'Actions' },
      ],
      search: '',
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      modalDelete: false,
      selectPO:'',
    }
  },
  mounted() {
    
    
    this.$store.dispatch("dealer/getCurrentDealer"); 
    this.$store
      .dispatch('auth/fetchUser')
      .catch(err => console.log(err))
    
    this.getPurchase({ page:this.currentPage, entry:this.perPage,orderStatus: 'ALL', allAccess: this.permission.all })
    .then((data) => this.processMetadata(data.metadata));
      
  },
  computed: {
    ...mapGetters({
      purchase: 'purchase/purchase',
    }),
    mappedPurchase(){
      return this.purchase.map(item => {
        return {
          ...item,
          rspOrderNumber: item.PONumber.includes('*') ? item.PONumber.substring(0, 11) : item.PONumber,
          customerPo: item.PONumber.includes('*') ? item.PONumber.substring(12) : ""
        }
      })
    },
    currentDealer() {
      var tempDealer = this.$store.getters["dealer/getCurrentDealers"]
      
      if(tempDealer === null || tempDealer === undefined){
        tempDealer = {
          status : "Not Allowed"
        }
      }else{
        tempDealer = {
          status : "Allowed"
        }
      }
      return tempDealer
    },
    user() {
      return this.$store.getters['auth/getActiveUser'];
    },
    permission() {
      //console.log(userAccess("Order", "order_menu"));
      
      return userAccess("Order", "order_menu");
    },
    checkPermissionAdd(){
      let tempPermission = false
      //console.log('currentDealer',this.currentDealer);
      if (this.currentDealer.status!=='Not Allowed' && this.permission.add){
        tempPermission = true
      }

      if(this.user.roleRef){
        if(this.user.roleRef.name==="Super Admin"||this.permission.add){
          tempPermission = true
        }
        
        
      }
      return tempPermission
    }
  },
  methods: {
    ...mapActions({
      getPurchase: 'purchase/getPurchase',
    }),
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.getPurchase({ 
        search: (this.search!="")?this.search:undefined,
        page: this.currentPage, 
        entry: this.perPage
        ,orderStatus: 'ALL', allAccess: this.permission.all
      }).then((data) => this.processMetadata(data.metadata));
    }, 500),
    dates(date) {
      //console.info(date);
      return dateFormat(date);
    },
    onPageChange(page) {
      this.currentPage=page;

      this.getPurchase({ 
        search: (this.search!="")?this.search:undefined,
        page: this.currentPage, 
        entry: this.perPage
        ,orderStatus: 'ALL', allAccess: this.permission.all
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    deleteDraft(row){
      this.modalDelete = true;
      this.selectPO = row;
      // console.log(this.selectPO);
    },
    confirmDelete() {
      this.modalDelete = false
      this.$store
        .dispatch('purchase/deletePurchase', { id: this.selectPO._id })
        .catch(err => {
          console.error({ err })
          this.$bvToast.toast(err.response.data.error, {
            title: 'Failed',
            variant: 'danger',
            solid: true,
          })
        })
    },
    detailPO(row) {
      let idOrder = row.item._id;
      this.$router.push({ name: 'order-detail', params: {  id: idOrder } });
    },
    editDraft(row) {
      this.$router.push(`/order/draft/edit/${row.item._id}`);
    },
  },
  created() {
    document.title = 'Purchase Order | RSP';

    
    this.getPurchase({ page:this.currentPage, entry:this.perPage,orderStatus: 'ALL', allAccess: this.permission.all })
    .then((data) => this.processMetadata(data.metadata));
  },
}
</script>
<style lang="scss">
  .custom-row-height {
    min-height: 120px; /* Adjust this value as needed */
  }
</style>
